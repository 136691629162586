exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-case-study-meal-mesh-view-js": () => import("./../../../src/pages/case-study/meal-mesh-view.js" /* webpackChunkName: "component---src-pages-case-study-meal-mesh-view-js" */),
  "component---src-pages-check-js": () => import("./../../../src/pages/check.js" /* webpackChunkName: "component---src-pages-check-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-trendwatchlist-2025-js": () => import("./../../../src/pages/insights/trendwatchlist2025.js" /* webpackChunkName: "component---src-pages-insights-trendwatchlist-2025-js" */),
  "component---src-pages-offerings-js": () => import("./../../../src/pages/offerings.js" /* webpackChunkName: "component---src-pages-offerings-js" */),
  "component---src-pages-perspectives-js": () => import("./../../../src/pages/perspectives.js" /* webpackChunkName: "component---src-pages-perspectives-js" */),
  "component---src-pages-potfolio-js": () => import("./../../../src/pages/potfolio.js" /* webpackChunkName: "component---src-pages-potfolio-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-case-study-template-js": () => import("./../../../src/templates/case-study-template.js" /* webpackChunkName: "component---src-templates-case-study-template-js" */),
  "component---src-templates-insights-template-js": () => import("./../../../src/templates/insights-template.js" /* webpackChunkName: "component---src-templates-insights-template-js" */),
  "component---src-templates-projects-template-js": () => import("./../../../src/templates/projects-template.js" /* webpackChunkName: "component---src-templates-projects-template-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

